* {
  box-sizing: border-box;
}

/* background-color: rgba(25, 25, 25, 30) */
body {
  margin: 0;
  padding: 0;
  background-color: rgba(25, 25, 25, 30);
  color: whitesmoke;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 100%;
  /* border-bottom: 1px solid gray; */
}

.kami-logo {
  margin: 1rem;
  margin-left: 10%;
  width: 12rem;
  background-color: transparent;
}

.navbar-links {
  margin: 0;
  margin-right: 5%;
  padding: 1rem 0;
  display: block;
  background-color: transparent;
}

.navbar-links li {
  display: flex;
  justify-content: center;
  list-style: none;
  font-size: 14pt;
  padding: 0.2rem 20px;
}

.navbar-links a {
  text-decoration: none;
  color: white;
}

.navbar-links a:hover {
  color: white;
  font-weight: 600;
}

header {
  padding: 1rem 2rem 1rem;
  color: whitesmoke;
}

header h1 {
  font-size: 40px;
}

header h4 {
  padding: 1rem 2rem;
}

main {
  top: 60px;
  flex: 1;
  position: relative;
  margin-bottom: 1rem;
}

footer {
  width: 100%;
  margin-top: 1rem;
  padding: 1rem 0 1rem;
  color: #f9f9fa;
  font-size: medium;
}

/* Card Section */

.cards-container {
  max-width: 100vw;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  flex-wrap: wrap;
  /* z-index: 20; */
  background-color: #121617;
}

.nft {
  min-width: 250px;
  max-width: 350px;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s;
  background-color: transparent;
  justify-content: center;
}

.nft img {
  max-width: 100%;
  scale: 1.01;
  max-height: fit-content;
  padding: 0;
  /* background-color: #121617; */
}

.nft:hover {
  transform: translateY(-2px);
}

.nft:hover .send-btn {
  display: block;
}

.nft .send-btn {
  position: absolute;
  bottom: 0.8rem;
  left: 2rem;
  display: none;
  background-color: cornflowerblue;
  color: #f9f9fa;
  text-decoration: none;
  font-weight: 700;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  width: max-content;
  transition: background-color 0.3s;
}

.send-btn:hover {
  background-color: rgb(70, 122, 168);
  border-color: whitesmoke;
  border-width: 3px;
  box-shadow: 1px 1px 15px wheat;
}

.support {
  margin-top: 0.5rem;
  font-size: 14pt;
  font-style: italic;
  color: lightgray;
}

.link {
  text-decoration: none;
  padding: 0 0.3rem;
  transition: 0.3s;
}

.link:hover {
  /* color: dodgerblue; */
  color: lightblue;
  font-size: 1.3rem;
}

.spacer {
  position: relative;
  height: 6rem;
}

@media (max-width: 600px) {
  .cards-container {
    gap: 2rem;
  }

  .navbar {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .kami-logo {
    margin: 0;
  }

  .navbar-links li {
    margin: 0;
    font-size: 14pt;
    text-align: center;
  }
  /* .buttons {
    display: inline-block;
    position: relative;
    top: -14rem;
    right: 2rem;
    text-align: right;
    width: 100%;
  } */
}
