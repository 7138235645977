.display-landing-page {
  background-color: var(--black);
  border: 1px none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* flex-wrap: wrap; */
  height: 1080px;
  padding: 58px 0;
  width: 1920px;
}

/* .frame {
  background-color: var(--black);
  height: 81px;
  margin-left: 1px;
  margin-top: -9rem;
  width: 325px;
} */

.welcome-to-the-kami {
  background-color: var(--black);
  letter-spacing: 0;
  line-height: 37.5px;
  margin-top: 50px;
  min-height: 184px;
  text-align: center;
  width: 940px;
}

.span1 {
  background-color: var(--black);
  color: var(--quarter-spanish-white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-style: italic;
  font-weight: 400;
}

.overlap-group {
  background-color: var(--black);
  height: 441px;
  /* margin-top: 56px; */
  margin-top: -2.5rem;
  position: relative;
  width: 538px;
}

.rent-display-use {
  background-color: var(--black);
  color: transparent;
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xl);
  font-weight: 200;
  left: 113px;
  letter-spacing: 0;
  line-height: 65px;
  position: absolute;
  top: 0px;
  transform: rotate(-90deg);
  width: 312px;
  text-align: left;
}

.span1-1 {
  background-color: var(--black);
  color: #ffe305;
}

.frame-190 {
  align-items: center;
  border: 1px solid;
  border-color: var(--quarter-spanish-white);
  display: flex;
  justify-content: center;
  left: -3.5rem;
  padding: 50px 20px 35px;
  position: absolute;
  top: -2rem;
  width: 650px;
  height: 7rem;
  z-index: 0;
}

.x2022-manyfold-pte {
  background-color: var(--black);
  color: var(--quarter-spanish-white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-weight: 400;
  left: 72px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 500px;
  width: 394px;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header .logo {
  flex: 1;
  margin-top: 1rem;
}

.header .playstore {
  flex: 1;
  margin-bottom: -3rem;
  text-decoration: none;
  cursor: auto;
}

.header .playstore > a > img {
  width: 25%;
}

@media only screen and (max-width: 550px) {
  .display-landing-page {
    width: 600px;
    flex-direction: column;
    box-sizing: border-box;
    flex-basis: 1;
    margin-top: -5rem;
  }

  .frame {
    margin-top: 10px;
    max-width: 300px;
  }

  .welcome-to-the-kami {
    width: 50%;
    font-size: 16pt;
    line-height: 1.8rem;
    text-align: center;
    margin-bottom: 3.5em;
  }

  .welcome-to-the-kami .sp1 {
    font-size: 18pt;
  }

  .frame-190 {
    width: 60%;
    align-self: center;
    margin: 0 30%;
  }

  .rent-display-use {
    margin: -2rem 1px auto;
    line-height: 2.5rem;
  }

  .rent-display-use .inter-extra-light-tundora-55px {
    font-size: 40px;
  }
  .rent-display-use .span1-1 {
    font-size: 40px;
  }

  .x2022-manyfold-pte {
    left: 14%;
    top: 350px;
  }
}
