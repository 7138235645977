* {
  position: relative;
  text-align: center;
  background-color: rgba(25, 25, 25, 30);
  font-size: 24px;
}

.device-modal .modal-content {
  background-color: #000;
  color: #fff;
  border-color: white;
  border-width: 1px;
  border-radius: 2px;
}

.logo {
  position: relative;
  margin-top: -12em;
  height: auto;
}

.device {
  display: flex;
  color: whitesmoke;
}

.device:hover {
  background-color: dimgray;
}

.wallet-button-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -200px;
}

.wallet-button {
  background-color: black;
  color: cornflowerblue;
  font-size: 40px;
  font-weight: 700;
  padding: 0.51em 1em;
  border: 1px solid cornflowerblue;
  border-radius: 35px;
  cursor: pointer;
}

.footer {
  position: fixed;
  bottom: 80px;
  width: 100%;
}

.footer .links {
  color: white;
  font-size: 30px;
  width: 100%;
}

.footer .links a {
  text-decoration: none;
  color: white;
}

.footer .copyright {
  color: cornflowerblue;
  font-size: 20px;
  width: 100%;
}
