:root {
  --black: #1919191e;
  --quarter-spanish-white: #f7f4e3;
  --tundora: #444343;
  --turbo: #ffe305;

  --font-size-l: 32px;
  --font-size-m: 25px;
  --font-size-s: 18px;
  --font-size-xl: 55px;
  --font-size-xs: 12px;

  --font-family-inter: 'Inter', Helvetica;
}

.inter-normal-quarter-spanish-white-25px {
  background-color: var(--black);
  color: var(--quarter-spanish-white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.inter-extra-light-tundora-55px {
  background-color: var(--black);
  color: var(--tundora);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 200;
}

.inter-bold-quarter-spanish-white-32px {
  background-color: var(--black);
  color: var(--quarter-spanish-white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.inter-normal-quarter-spanish-white-12px {
  background-color: var(--black);
  color: var(--quarter-spanish-white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.inter-bold-quarter-spanish-white-15px {
  color: var(--quarter-spanish-white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}

.inter-bold-quarter-spanish-white-25px {
  color: var(--quarter-spanish-white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}
