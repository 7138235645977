.connect-wallet-cur {
  line-height: 32px;
  margin-top: -1px;
  text-align: center;
  width: fit-content;
  z-index: 10;
}

.span0 {
  letter-spacing: 12.8px;
  line-height: 49px;
}

.span1-2 {
  color: var(--quarter-spanish-white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  /* line-height: 39.9px; */
  line-height: 1px;
}

@media only screen and (max-width: 768px) {
  .connect-wallet-cur {
    line-height: 25px;
    margin-top: -1px;
    text-align: center;
    width: fit-content;
    z-index: 10;
  }

  .span0 {
    font-size: var(--font-size-m);
    letter-spacing: 6px;
    line-height: 30px;
  }

  .span1-2 {
    color: var(--quarter-spanish-white);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-xs);
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0;
    /* line-height: 20px; */
    line-height: 10px;
  }
}
